import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import {
  ModalFeatureBlock,
  ModalFeaturesList,
  ModalLead,
  ModalList,
  ModalMainImage,
  ModalMainWrapper,
} from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/driver-facing-camera.png';
import icon01 from '../../assets/images/mix-vision/icon_2-1.png';
import icon02 from '../../assets/images/mix-vision/icon_2-2.png';
import icon03 from '../../assets/images/mix-vision/icon_2-3.png';
import icon04 from '../../assets/images/mix-vision/icon_2-4.png';
import icon05 from '../../assets/images/mix-vision/icon_2-5.png';
import icon06 from '../../assets/images/mix-vision/icon_2-6.png';

const MixVisionModal02 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Камера, направленная на водителя"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        Камера, направленная на водителя, определяет такие события как: использование ремня безопасности, использование телефона, усталость и курение.
      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="Driver-Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Крепится на приборной панели или сбоку</li>
            <li>Распознает действия водителя</li>
            <li>Ч/Б изображение с инфракрасной подсветкой</li>
            <li>Определяет зрачки водителя даже через солнечные очки, ночью или в условиях плохой видимости</li>
            <li>Безопасно для глаз водителя 920P 20 кадров в секунду</li>
            <li>Встроенный микрофон для записи звука</li>
          </ModalList>
        </div>
      </ModalMainWrapper>

      <ModalFeaturesList>
        <ModalFeatureBlock>
          <div>
            <img src={icon01} height="50px" alt="Fatigue Warning" />
          </div>
          <div>
            <p>
              <strong>Оповещение об усталости</strong>
            </p>
            <p>
              Запускается во время зевания, когда глаза закрыты более 2х секунд или во время частого/избыточного моргания
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon02} height="50px" alt="Seat Belt Usage" />
          </div>
          <div>
            <p>
              <strong>Использование ремня безопасности</strong>
            </p>
            <p>Определяет, если ремень безопасности не используется более 4х секунд</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon03} height="50px" alt="Phone Use" />
          </div>
          <div>
            <p>
              <strong>Использование телефона</strong>
            </p>
            <p>Телефон у лица в течение 5 секунд</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon04} height="50px" alt="Driver Distraction" />
          </div>
          <div>
            <p>
              <strong>Отвлечение водителя</strong>
            </p>
            <p>Взгляд водителя направлен вверх или вниз в течение 4х секунд</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon05} height="50px" alt="Smoking" />
          </div>
          <div>
            <p>
              <strong>Курение</strong>
            </p>
            <p>Курение или жест курения</p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon06} height="50px" alt="Missing Driver" />
          </div>
          <div>
            <p>
              <strong>Отсутствие водителя</strong>
            </p>
            <p>Камера водителя закрыта или водитель отсутствует</p>
          </div>
        </ModalFeatureBlock>
      </ModalFeaturesList>
    </Modal>
  );
});

export default MixVisionModal02;
