import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { DeviceBlock, DeviceBlockLink, DeviceBlockTitle, DevicesWrapper, MixVisionStyled } from './MixVision.styled';
import headerBackground from '../../assets/images/mix-vision/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import imageDashboardCameras from '../../assets/images/mix-vision/modal_1-1.png';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import MixVisionModal01 from './Modal01';
import MixVisionModal02 from './Modal02';
import MixVisionModal03 from './Modal03';
import MixVisionModal04 from './Modal04';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import { disableBodyScroll } from '../../lib/scrollLock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixVision(): JSX.Element {
  const modal01 = useModal(MixVisionModal01);
  const modal02 = useModal(MixVisionModal02);
  const modal03 = useModal(MixVisionModal03);
  const modal04 = useModal(MixVisionModal04);
  const openModal = (id: number) => {
    disableBodyScroll();
    switch (id) {
      case 1:
        modal01.show().catch((error) => console.log(error));
        break;
      case 2:
        modal02.show().catch((error) => console.log(error));
        break;
      case 3:
        modal03.show().catch((error) => console.log(error));
        break;
      case 4:
        modal04.show().catch((error) => console.log(error));
        break;
      default:
        break;
    }
  };

  return (
    <MixVisionStyled>
      <SEO
        title="RGRauto | MiX Vision II"
      />
      <Header
        title="MiX Vision II"
        description="Решение MiX Vision II™ использует камеры с ИИ для мониторинга действий на дороге и внутри машины. Подаёт незамедлительные оповещения водителю и вашему бизнесу для того, чтобы выявить небезопасный стиль вождения, снизить риски для автопарка, помочь с анализом ДТП, снизить затраты на страховку и защитить ценные грузы."
        backgroundImage={headerBackground}
      />

      <Section style={{ paddingBottom: '0' }}>
        <Container>
          <BorderBlock image={imageDashboardCameras} imagePosition="right" imageWidth={320} imageBackground="#ffffff">
            <p>
              Водители и менеджеры автопарка получают оповещения в режиме реального времени о рискованном вождении при помощи встроенных видеорегистраторов с искусственным интеллектом, направленных на дорогу и в кабину ТС. {' '}
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Распознавание / Предотвращение</SectionTitle>
          <p>
            Камеры, направленные на дорогу и расположенные в кабине ТС, обнаруживают и предотвращают столкновения и рискованное поведение водителя, сохраняя связь между вами и защищая ваших водителей и транспортные средства.
          </p>
          <DevicesWrapper>
            <DeviceBlock>
              <DeviceBlockTitle>Камера, направленная на дорогу</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(1);
                  }}
                  type="button"
                >
                  Подробнее
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>Камера, направленная на водителя</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(2);
                  }}
                  type="button"
                >
                  Подробнее
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>Камера внутри салона</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(3);
                  }}
                  type="button"
                >
                  Подробнее
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
            <DeviceBlock>
              <DeviceBlockTitle>Дисплей «Интеллектуальный тренер по вождению»</DeviceBlockTitle>
              <DeviceBlockLink>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(4);
                  }}
                  type="button"
                >
                  Подробнее
                </button>
              </DeviceBlockLink>
            </DeviceBlock>
          </DevicesWrapper>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixVisionStyled>
  );
}

export default MixVision;
