import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import FormRequestCall from '../../components/Form/FormRequestCall';

const RequestCallModal = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Обратный звонок от специалиста"
      onClose={() => {
        modal.remove();
      }}
    >
      <p>Пожалуйста, заполните приведенную ниже форму, и мы перезвоним Вам в ближайшее время.</p>
      <FormRequestCall />
    </Modal>
  );
});

export default RequestCallModal;
