import Joi from 'joi';
import countryList from '../../data/countryList';
import fleetSizeList from '../../data/fleetSizeList';
import industryList from '../../data/industryList';

export const contactSchema = Joi.object({
  firstName: Joi.string().required().label('Фамилия'),
  lastName: Joi.string().required().label('Имя'),
  companyName: Joi.string().label('Название компании').allow(''),
  jobName: Joi.string().label('Должность').allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email'),
  phone: Joi.string().required().label('Телефон'),
  country: Joi.string()
    .valid(...countryList)
    .required()
    .messages({
      'any.only': `{{#label}} должна быть действительная страна`,
    })
    .label('Country'),
  fleetSize: Joi.string()
    .valid(...fleetSizeList)
    .required()
    .messages({
      'any.only': `{{#label}} не может быть пустым`,
    })
    .label('Fleet Size'),
  message: Joi.optional(),
  subscribe: Joi.bool().required(),
  captcha: Joi.number(), // TODO: Сделать required
});

export const requestSchema = Joi.object({
  firstName: Joi.string().required().label('Фамилия'),
  lastName: Joi.string().required().label('Имя'),
  companyName: Joi.string().label('Название компании').allow(''),
  jobName: Joi.string().label('Должность').allow(''),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .label('Email'),
  phone: Joi.string().required().label('Телефон'),
  city: Joi.string().required().label('Город'),
  country: Joi.string()
    .valid(...countryList)
    .required()
    .messages({
      'any.only': `{{#label}} должна быть действительная страна`,
    })
    .label('Country'),
  fleetSize: Joi.string()
    .valid(...fleetSizeList)
    .required()
    .messages({
      'any.only': `{{#label}} не может быть пустым`,
    })
    .label('Fleet Size'),
  industry: Joi.string()
    .valid(...industryList)
    .required()
    .messages({
      'any.only': `{{#label}} не может быть пустым`,
    })
    .label('Industry'),
  message: Joi.optional(),
  subscribe: Joi.bool().required(),
  captcha: Joi.number(), // TODO: Сделать required
});

export const callRequestSchema = Joi.object({
  name: Joi.string().required().label('Имя'),
  phone: Joi.string().required().label('Телефон'),
  time: Joi.optional().label('Время звонка'),
});
