import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { GreenroadDriveStyled } from './GreenroadDrive.styled';
import headerBackground from '../../assets/images/drive/header.jpg';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Section from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import monoblockImage from '../../assets/images/drive/monoblock.png';
import notebookImage from '../../assets/images/drive/notebook.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GreenroadDrive(): JSX.Element {
  return (
    <GreenroadDriveStyled>
      <SEO
        title="RGRauto | GreenRoad Drive"
      />
      <Header
        title={
          <>
            GreenRoad Drive<sup>™</sup>
            <br />
            Система управления <br /> 
            автопарком на базе <br />
            мобильного приложения
          </>
        }
        description="Превратите мобильные устройства водителей в интеллектуальных тренеров по вождению и обучению — для сотрудников, подрядчиков и удаленных водителей."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock image={monoblockImage} imagePosition="right" imageWidth={350}>
            <p>
              Мобильное приложение <strong>GreenRoad Drive™</strong> загружается и готово к использованию за считанные минуты, поддерживая процессы цифровизации предприятия и обеспечивая безопасность в бизнесе. GreenRoad Drive™ может работать в фоновом режиме, не конфликтуя с работой других мобильных приложений.
            </p>
          </BorderBlock>
        </Container>
      </Section>
      <Section>
        <Container>
          <BorderBlock image={notebookImage} imagePosition="left" imageWidth={700} imageOffset={{ left: -60 }}>
            <p>
              Применяйте эффективный и упреждающий (превентивный, профилактический?) подход к управлению безопасностью автопарка и операционными процессами, используя информационные панели KPI GreenRoad и данные об отклонениях от нормы с полной детализацией на основе искусственного интеллекта (ИИ). 
              Доступно через интернет-браузер или приложение GreenRoad Drive™.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GreenroadDriveStyled>
  );
}

export default GreenroadDrive;
