import React from 'react';
import { AboutStyled } from './About.styled';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import aboutImage from '../../assets/images/about-us/about-us.jpg';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
import { BigTextStyled } from '../../components/PageTitle/BigText.styled';
import { PageLeadStyled } from '../../components/PageTitle/PageLead.styled';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function About(): JSX.Element {
  return (
    <AboutStyled>
      <SEO
        title="RGRauto | О нас"
      />
      <Section style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle>О нас</PageTitle>
          <PageLeadStyled>
            Компания RGRauto является ведущим мировым поставщиком комплексных решений по обеспечению безопасности и оптимизации работы автопарков различных размеров в различных отраслях промышленности.
          </PageLeadStyled>
          <p>
            RGRauto сотрудничает с такими лидерами рынка, как Cipia, Cloud-Wise, Geotab, GreenRoad, Ituran, Mobileye, MiX
            Telematics, Orpak-Gilbarco, и интегрирует их в уникальные экономичные решения "под ключ".
          </p>
          <BorderBlock image={aboutImage} imagePosition="left" imageWidth={527} imageBackground={null}>
            <p style={{fontSize: '16px', padding: '0 8px'}}>
              Широкий спектр инновационных решений, таких как Connected ADAS, AI DMS, IoT Telematics и FMS, позволяет
              RGRauto предложить обширный портфель различных и конкурентоспособных решений для удовлетворения уникальных
              требований и бюджетов различных автопарков.
              RGRauto проводит испытания, демонстрацию, пилотные проекты и проверку концепции (POC), чтобы помочь потенциальным заказчикам оценить свои решения.
            </p>
          </BorderBlock>
          <p>
            Мы с уверенностью обслуживаем как частные малые и средние автопарки, так и крупные корпоративные автопарки, такие как ALD, Arval,
            AstraZeneca, Boehringer-Ingelheim, Coca-Cola, Danone, LeasePlan, Linde Gas, Maxxium, Nabors, Orange Business
            Services, Orica, Philip&nbsp;Morris, Praxair, Schlumberger, Shell, города и местные власти и многие другие.
          </p>
        </Container>
      </Section>

      <section>
        <Container>
          <BigTextStyled>Мы превращаем отличные продукты в умные решения!</BigTextStyled>
        </Container>
      </section>

      <Section>
        <Container>
          <SectionTitle>Связаться с нами</SectionTitle>
          <p style={{ fontSize: '18px' }}>У вас есть вопрос?</p>
          <Card>
            <CardDescription>
              Пожалуйста, заполните приведенную ниже форму, и мы <br /> 
              свяжемся с Вами в ближайшее время.
            </CardDescription>
            <FormContact />
          </Card>
        </Container>
      </Section>
    </AboutStyled>
  );
}

export default About;
