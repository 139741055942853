import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import { ModalLead, ModalList, ModalMainImage, ModalMainWrapper } from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/ai-drving-coach.png';

const MixVisionModal04 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Дисплей «Интеллектуальный тренер по вождению»"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>В режиме реального времени на дисплее отображаются ненавязчивые визуальные оповещения, которые служат обратной связью для водителя.</ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="AI Driving Coach" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Крепится на приборную панель</li>
            <li>Тренинг водителя в реальном времени</li>
            <li>Визуальные предупреждения на устройстве</li>
            <li>Может использоваться для замены звуковых / голосовых оповещений камеры визуальными оповещениями</li>
          </ModalList>
        </div>
      </ModalMainWrapper>
    </Modal>
  );
});

export default MixVisionModal04;
