import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { GeotabStyled } from './Geotab.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/geotab.jpg';
import companyImage from '../../assets/images/companies/geotab.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Geotab(): JSX.Element {
  return (
    <GeotabStyled>
      <SEO
        title="RGRauto | Geotab"
      />
      <Container>
        <PageTitle>Geotab</PageTitle>
        <CompanyCard
          name="Geotab"
          logo={companyLogo}
          image={companyImage}
          buttons={[
            {
              title: 'О GO9',
              url: '/geotab/go9',
            },
          ]}
        >
          <p>
            Geotab
          </p>
          <p>
            GO9
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Записаться на пилот</SectionTitle>
          <CardText>
            Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </GeotabStyled>
  );
}

export default Geotab;
