import React, { MouseEvent, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Collapse from "@kunukn/react-collapse";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useModal } from "@ebay/nice-modal-react";
import {
  ExtraMenu,
  ExtraMenuColumn,
  ExtraMenuDivider,
  ExtraMenuLinkList,
  ExtraMenuSubTitle,
  ExtraMenuTitle,
  MainMenuButton,
  MainMenuList,
  MainMenuLogo,
  MainMenuWrapper,
  MenuBlockWrapper,
  MenuWrapper,
  MobileMenuToggleButton
} from "./Menu.styled";
import { Container } from "../Container/Container.styled";
import logo from "../../assets/images/logo-menu.png";
import searchIcon from "../../assets/icons/search.svg";
import RequestCallModal from "../../modals/RequestCallModal/RequestCallModal";
import { disableBodyScroll } from "../../lib/scrollLock";
import SearchModal from "../../modals/SearchModal/SearchModal";

const Menu = (): JSX.Element => {
  const [solutionsMenuVisible, setSolutionsMenuVisible] = useState(false);
  const [productsMenuVisible, setProductsMenuVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  // TODO: CHANGE AFTER ENABLE TOPBAR <start>
  const menuWrapperStyle = {top: '0'};
  // const [menuWrapperStyle, setMenuWrapperStyle] = useState({
  //   top: "40px" // Topbar height
  // });

  // Sticky menu, also see hack in Topbar
  // useScrollPosition(
  //   ({ currPos }) => {
  //     const isFixed = currPos.y > -40;
  //     const style = {
  //       top: isFixed ? `${40 + currPos.y}px` : `0`
  //     };
  //     if (JSON.stringify(style) === JSON.stringify(menuWrapperStyle)) return;
  //     setMenuWrapperStyle(style);
  //   },
  //   [menuWrapperStyle]
  // );
  // TODO: CHANGE AFTER ENABLE TOPBAR </end>

  // Close menu after location changes
  const location = useLocation();
  React.useEffect(() => {
    setProductsMenuVisible(false);
    setSolutionsMenuVisible(false);
    setMobileMenuVisible(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  const toggleSolutionsMenu = (e: MouseEvent) => {
    setMobileMenuVisible(false);
    setProductsMenuVisible(false);
    setSolutionsMenuVisible(!solutionsMenuVisible);
    e.preventDefault();
  };
  const toggleProductsMenu = (e: MouseEvent) => {
    setMobileMenuVisible(false);
    setSolutionsMenuVisible(false);
    setProductsMenuVisible(!productsMenuVisible);
    e.preventDefault();
  };
  const toggleMobileMenu = (e: MouseEvent) => {
    setSolutionsMenuVisible(false);
    setProductsMenuVisible(false);
    setMobileMenuVisible(!mobileMenuVisible);
    e.preventDefault();
  };

  const requestCallModal = useModal(RequestCallModal);
  const openRequestForm = () => {
    requestCallModal.show().catch((error) => {
      console.log(error);
    });
  };

  const searchModal = useModal(SearchModal);
  const openSearchModal = (e: MouseEvent) => {
    e.preventDefault();
    disableBodyScroll();
    searchModal.show().catch((error) => {
      console.log(error);
    });
  };

  return (
    <MenuBlockWrapper style={{ ...menuWrapperStyle }}>
      <MenuWrapper>
        <Container>
          <MainMenuWrapper>
            <MainMenuLogo>
              <NavLink exact to="/">
                <img src={logo} alt="RGRauto" title="RGRauto" width="auto" height="50" />
              </NavLink>
            </MainMenuLogo>
            <MainMenuList>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={toggleSolutionsMenu}>
                Решения
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={toggleProductsMenu}>
                Продукция
              </a>
              <NavLink exact to="/demo">
                Пилот
              </NavLink>
              <NavLink exact to="/about">
                О нас
              </NavLink>
              <NavLink exact to="/contact">
                Контакты
              </NavLink>
              <a href="#" onClick={(e) => openSearchModal(e)}>
                <img src={searchIcon} style={{ display: "block" }} alt="search" height="16" />
              </a>
            </MainMenuList>
            <MainMenuButton onClick={openRequestForm}>Запросить звонок</MainMenuButton>
            <MobileMenuToggleButton onClick={toggleMobileMenu}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <path
                  stroke="rgba(255, 255, 255, 0.5)"
                  strokeLinecap="round"
                  strokeMiterlimit="10"
                  strokeWidth="2"
                  d="M4 7h22M4 15h22M4 23h22"
                />
              </svg>
            </MobileMenuToggleButton>
          </MainMenuWrapper>
        </Container>
      </MenuWrapper>
      <Collapse isOpen={mobileMenuVisible}>
        <ExtraMenu>
          <ExtraMenuColumn style={{ width: "100%" }}>
            <ExtraMenuTitle>
              <NavLink exact to="/">
                Главная
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <NavLink exact to="/products">
                Продукция
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <NavLink exact to="/solutions">
                Решения
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <NavLink exact to="/demo">
                Пилот(Демонстрация)
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <NavLink exact to="/about">
                О нас
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <NavLink exact to="/contact">
                Контакты
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuTitle>
              <a href="#" onClick={(e) => openSearchModal(e)}>
                Поиск
              </a>
            </ExtraMenuTitle>
          </ExtraMenuColumn>
        </ExtraMenu>
      </Collapse>
      <Collapse isOpen={solutionsMenuVisible}>
        <ExtraMenu>
          <ExtraMenuColumn>
            <ExtraMenuTitle>
              <NavLink exact to="/mobileye">
                Передовые системы помощи водителю (ADAS)
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/mobileye">
                Mobileye
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/mobileye/mobileye-8-connect">
                  Mobileye 8 Connect
                </NavLink>
              </li>
              {/*<li>*/}
              {/*  <NavLink exact to="/mobileye/mobileye-shield">*/}
              {/*    Mobileye Shield+*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ExtraMenuLinkList>
          </ExtraMenuColumn>
          <ExtraMenuColumn>
            <ExtraMenuTitle>
              <NavLink exact to="/driver-monitoring-system">
                Системы контроля за состоянием водителя (DMS)
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/cipia">
                Cipia
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/cipia/fleet-sense-fs10">
                  Fleet Sense FS10
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/greenroad">
                GreenRoad
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/greenroad/videosense">
                  Videosense™
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/mix-telematics">
                MiX Telematics
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/mix-telematics/mix-vision-ii">
                  MiX Vision II
                </NavLink>
              </li>
            </ExtraMenuLinkList>
          </ExtraMenuColumn>
          <ExtraMenuColumn>
            <ExtraMenuTitle>
              <NavLink exact to="/fleet-telematics">
                Телематика и системы управления безопасностью автопарка
              </NavLink>
            </ExtraMenuTitle>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/cloud-wise">
                Cloud-Wise
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/cloud-wise/i-sticker">
                  I-Sticker
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/geotab">
                Geotab
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/geotab/go9">
                  GO9
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/greenroad">
                GreenRoad
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/greenroad/drive">
                  Drive™
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            {/*<ExtraMenuSubTitle>*/}
            {/*  <NavLink exact to="/ituran">*/}
            {/*    Ituran*/}
            {/*  </NavLink>*/}
            {/*</ExtraMenuSubTitle>*/}
            {/*<ExtraMenuLinkList>*/}
            {/*  <li>StarLink</li>*/}
            {/*</ExtraMenuLinkList>*/}
            {/*<ExtraMenuDivider />*/}
            <ExtraMenuSubTitle>
              <NavLink exact to="/mix-telematics">
                MiX Telematics
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/mix-telematics/mix-tracking">
                  MyMiX Tracking™
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/mix-telematics/mix-fleet-manager">
                  MiX Fleet Manager™
                </NavLink>
              </li>
            </ExtraMenuLinkList>
          </ExtraMenuColumn>
        </ExtraMenu>
      </Collapse>
      <Collapse isOpen={productsMenuVisible}>
        <ExtraMenu>
          <ExtraMenuColumn>
            <ExtraMenuSubTitle>
              <NavLink exact to="/cipia">
                Cipia
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/cipia/fleet-sense-fs10">
                  Fleet Sense FS10
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/cloud-wise">
                Cloud-Wise
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/cloud-wise/i-sticker">
                  I-Sticker
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/geotab">
                Geotab
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/geotab/go9">
                  GO9
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/greenroad">
                GreenRoad
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/greenroad/drive">
                  Drive™
                </NavLink>
              </li>
            </ExtraMenuLinkList>
          </ExtraMenuColumn>
          <ExtraMenuColumn>
            {/*<ExtraMenuSubTitle>*/}
            {/*  <NavLink exact to="/ituran">*/}
            {/*    Ituran*/}
            {/*  </NavLink>*/}
            {/*</ExtraMenuSubTitle>*/}
            {/*<ExtraMenuLinkList>*/}
            {/*  <li>StarLink</li>*/}
            {/*</ExtraMenuLinkList>*/}
            {/*<ExtraMenuDivider />*/}
            <ExtraMenuSubTitle>
              <NavLink exact to="/mix-telematics">
                MiX Telematics
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/mix-telematics/mix-tracking">
                  MyMiX Tracking™
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/mix-telematics/mix-fleet-manager">
                  MiX Fleet Manager™
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/mix-telematics/mix-vision-ii">
                  MiX Vision II
                </NavLink>
              </li>
            </ExtraMenuLinkList>
            <ExtraMenuDivider />
            <ExtraMenuSubTitle>
              <NavLink exact to="/mobileye">
                Mobileye
              </NavLink>
            </ExtraMenuSubTitle>
            <ExtraMenuLinkList>
              <li>
                <NavLink exact to="/mobileye/mobileye-8-connect">
                  Mobileye 8 Connect
                </NavLink>
              </li>
              {/*<li>*/}
              {/*  <NavLink exact to="/mobileye/mobileye-shield">*/}
              {/*    Mobileye Shield+*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
            </ExtraMenuLinkList>
          </ExtraMenuColumn>
        </ExtraMenu>
      </Collapse>
    </MenuBlockWrapper>
  );
};

export default Menu;
