import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { MixTelematictsStyled } from './MixTelematicts.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/mixtelematics.svg';
import companyImage from '../../assets/images/companies/mix-telematics.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixTelematicts(): JSX.Element {
  return (
    <MixTelematictsStyled>
      <SEO
        title="RGRauto | MiX Telematics"
      />
      <Container>
        <PageTitle>MiX Telematics</PageTitle>
        <CompanyCard
          name="MiX Telematics"
          logo={companyLogo}
          image={companyImage}
          buttons={[
            {
              title: 'MyMiX Tracking™',
              url: '/mix-telematics/mix-tracking',
            },
            {
              title: 'MiX Fleet Manager™',
              url: '/mix-telematics/mix-fleet-manager',
            },
            {
              title: 'MiX Vision II™',
              url: '/mix-telematics/mix-vision-ii',
            },
          ]}
        >
          <p>
              MiX Telematics
          </p>
          <p>
              MyMiX Tracking™, MiX Fleet Manager™, MiX Vision II™
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Записаться на пилот</SectionTitle>
          <CardText>
            Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </MixTelematictsStyled>
  );
}

export default MixTelematicts;
