import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import '../../assets/styles/slider.scss';
import slider01 from '../../assets/images/home/carousel-1.jpg';
import slider02 from '../../assets/images/home/carousel-2.jpg';
import slider03 from '../../assets/images/home/carousel-3.jpg';
import {
  AboutDescription,
  AboutImageWrapper,
  AboutTextWrapper,
  AboutTitle,
  AboutWrapper,
  AutoplaySliderWrapper,
  BottomSectionTitle,
  GetStartedBlock,
  GetStartedBlockButtonWrapper,
  GetStartedBlockFlex,
  HomeStyled,
  OutlinedButton,
  OutlinedWhiteButton,
  WhiteButton,
} from './Home.styled';
import Container from '../../components/Container/Container';
import SliderTextBlock from '../../components/SliderTextBlock/SliderTextBlock';
import Section from '../../components/Section/Section';
import getStartedLogo from '../../assets/images/home/get-started-logo.png';
import aboutSectionBg from '../../assets/images/home/about-bg.jpg';
import aboutSectionLogo from '../../assets/images/home/about-rgr.png';
import getStartedBottomBg from '../../assets/images/home/get-started-down-background.jpg';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormContact from '../../components/Form/FormContact';
import VendorsBlock from '../../components/VendorsBlock/VendorsBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

// eslint-disable-next-line
const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home(): JSX.Element {
  return (
    <HomeStyled>
      <SEO
        title="RGRauto"
      />
      <AutoplaySliderWrapper>
        <AutoplaySlider play cancelOnInteraction={false} interval={11000} fillParent bullets={false}>
          <div data-src={slider01}>
            <SliderTextBlock
              title="Передовые системы помощи водителю"
              description="Более 65 млн. автомобилей по всему миру оснащены технологией Mobileye. В то время как другие системы фиксируют столкновение, Mobileye предотвращает его."
              links={[
                {
                  title: 'Далее',
                  url: '/mobileye',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Записаться на пилот',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
          <div data-src={slider02}>
            <SliderTextBlock
              title="Системы мониторинга водителей"
              description="Решения в области компьютерного зрения для автопарков. Системы мониторинга водителей на основе искусственного интеллекта помогают водителям повысить безопасность и качество управления автомобилем, улучшить мобильность и повысить безопасность автопарка."
              links={[
                {
                  title: 'Далее',
                  url: '/driver-monitoring-system',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Записаться на пилот',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
          <div data-src={slider03}>
            <SliderTextBlock
              title="Телематика и системы управления безопасностью автопарка"
              description="Передовые решения для обеспечения безопасности и управления автопарком, предоставляющие операторам автопарков неограниченный доступ к информации об их транспортных средствах и водителях, а также множество функций, инструментов и отчетов, позволяющих повысить безопасность водителей, эффективность работы автопарка и максимизировать прибыль от инвестиций."
              links={[
                {
                  title: 'Далее',
                  url: '/fleet-telematics',
                  type: 'nav',
                  variant: 'default',
                },
                {
                  title: 'Записаться на пилот',
                  url: '/demo',
                  type: 'nav',
                  variant: 'white',
                },
              ]}
            />
          </div>
        </AutoplaySlider>
      </AutoplaySliderWrapper>
      <Section>
        <Container>
          <GetStartedBlock>
            <GetStartedBlockFlex>
              <img src={getStartedLogo} alt="RGR" height={60} />
              <div>Мы превращаем отличные продукты в интеллектуальные решения</div>
            </GetStartedBlockFlex>
            <GetStartedBlockButtonWrapper>
              <OutlinedButton exact to="/contact">
                Поехали
              </OutlinedButton>
            </GetStartedBlockButtonWrapper>
          </GetStartedBlock>
        </Container>
      </Section>

      <Section style={{ padding: '0' }} backgroundColor="#001C54" backgroundImage={aboutSectionBg}>
        <Container>
          <AboutWrapper>
            <AboutTextWrapper>
              <AboutTitle>О компании RGRauto</AboutTitle>
              <AboutDescription>
                RGRauto - ведущий мировой поставщик комплексных решений по обеспечению безопасности и оптимизации работы автопарков различных размеров в различных отраслях промышленности
              </AboutDescription>
              <OutlinedWhiteButton exact to="/about">
                Далее
              </OutlinedWhiteButton>
            </AboutTextWrapper>
          </AboutWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Наши клиенты</SectionTitle>
          <p>Нам доверяют ведущие мировые бренды</p>
          <CustomersBlock />
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Связаться с нами</SectionTitle>
          <p style={{ fontSize: '18px' }}>У вас есть вопрос?</p>
          <Card>
            <CardDescription>
              Пожалуйста, заполните приведенную ниже форму, и мы <br /> 
              свяжемся с Вами в ближайшее время.
            </CardDescription>
            <FormContact />
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Мы сотрудничаем с ведущими мировыми вендорами</SectionTitle>
          <p style={{ fontSize: '18px' }}>
            Мы интегрируем продукты избранной группы лидеров отрасли для предоставления лучших в своем классе решений и услуг для наших клиентов
          </p>
          <VendorsBlock />
        </Container>
      </Section>

      <Section
        backgroundColor="#001C54"
        backgroundImage={getStartedBottomBg}
        style={{ minHeight: '300px', textAlign: 'center' }}
      >
        <Container>
          <BottomSectionTitle>Сделайте свой автопарк безопаснее и умнее с помощью RGRAuto</BottomSectionTitle>
          <WhiteButton exact to="/contact">
            Поехали
          </WhiteButton>
        </Container>
      </Section>
    </HomeStyled>
  );
}

export default Home;
