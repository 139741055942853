import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Container from '../../components/Container/Container';
import { DemoStyled, HeaderBackground } from './Demo.styled';
import Section from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import { CardDescription } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Demo(): JSX.Element {
  return (
    <DemoStyled>
      <SEO
        title="RGRauto | Пилот"
      />
      <HeaderBackground />
      <Section backgroundColor="transparent" style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle variant="inverted">Записаться на пилот</PageTitle>
          <p style={{ color: '#FFFFFF', marginBottom: '36px' }}>Мы рады, что Вас заинтересовали наши решения!</p>
          <Card>
            <CardDescription>
              Чтобы запросить демонстрацию, заполните приведенную ниже форму <br />
              и мы свяжемся с Вами в ближайшее время
            </CardDescription>
            <FormRequest />
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Мы с удовольствием обслуживаем как частные малые и средние автопарки, так и крупные корпоративные автопарки</SectionTitle>
          <CustomersBlock />
        </Container>
      </Section>
    </DemoStyled>
  );
}

export default Demo;
