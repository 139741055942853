const industryList: string[] = [
  'Сельское хозяйство',
  'Авиакомпании, аэропорты и авиаперевозки',
  'Автомобильная промышленность',
  'Строительство',
  'Распространение',
  'Продажи и обслуживание на местах',
  'Продукты питания и напитки/FMCG',
  'Грузоперевозки и логистика',
  'Государственные и местные органы власти',
  'Здравоохранение и фармацевтика',
  'Страхование',
  'Лизинговые и финансовые услуги',
  'Переезд и хранение',
  'Нефтегазовая и горнодобывающая промышленность',
  'Пассажирские перевозки',
  'Полиция и службы быстрого реагирования',
  'Профессиональные услуги',
  'Общественный транспорт',
  'Недвижимость',
  'Телекоммуникации',
  'Коммунальные службы',
  'Переработка отходов',
  'Оптовая/розничная торговля',
];

export default industryList;
