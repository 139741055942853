import React from 'react';
import { BlueBoxContent, BlueBoxImage, BlueBoxSection, FeaturesWrapper, MixTrackingStyled } from './MixTracking.styled';
import headerBackground from '../../assets/images/mymix-tracking/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import imagePhone from '../../assets/images/mymix-tracking/about.png';
import imageFeatures01 from '../../assets/images/mymix-tracking/features1.png';
import imageFeatures02 from '../../assets/images/mymix-tracking/features2.png';
import imageFeatures03 from '../../assets/images/mymix-tracking/features3.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixTracking(): JSX.Element {
  return (
    <MixTrackingStyled>
      <SEO
        title="RGRauto | MyMiX Tracking"
      />
      <Header
        title="MyMiX Tracking"
        description="Инновационное управление автопарком на основе мобильного приложения. Отслеживайте местоположение и контролируйте стиль вождения водителя без необходимости покупки оборудования."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <SectionDescription>
            <p>
              С помощью приложения MyMiX Tracking™ вы можете следить за временными транспортными средствами и подрядчиками, а также отслеживать небезопасное поведение водителей — установка оборудования не требуется!
            </p>
            <hr />
          </SectionDescription>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>ЧТО ТАКОЕ MyMiX TRACKING?</SectionTitle>
          <p>
            MyMiX Tracking использует технологию мобильных телефонов, чтобы обеспечить мониторинг водителей в режиме реального времени при записи, измерении и обеспечении самокоррекции событий (не совсем понятен смысл предложения) рискованного поведения при вождении в реальном времени, включая превышение скорости, резкое торможение, резкое ускорение и использование мобильного телефона во время вождения.
          </p>
        </Container>
      </Section>

      <BlueBoxSection>
        <Container>
          <BlueBoxContent>
            <p>
              Решение на основе мобильного приложения является расширением MyMiX, комплексного приложения для водителей, которое также предоставляет ценную информацию и предупреждения для улучшения их стиля вождения, включая мгновенную звуковую обратную связь в кабине. Наша формула успеха позволяет вам начать работу с минимальными усилиями, ощутимой окупаемостью инвестиций и без прерывания вашей повседневной деятельности. (это лишний повтор, удалить? далее будет такое же предложение)
            </p>
          </BlueBoxContent>
          <BlueBoxImage>
            <img src={imagePhone} alt="phone" height="470px" />
          </BlueBoxImage>
        </Container>
      </BlueBoxSection>

      <Section>
        <Container>
          <SectionDescription>
            <p>
              Наша формула успеха позволяет вам начать работу с минимальными усилиями, ощутимой окупаемостью инвестиций и без прерывания вашей повседневной деятельности.
            </p>
          </SectionDescription>
          <FeaturesWrapper>
            <div>
              <img src={imageFeatures01} alt="MyMiX driver app" />
              <h3>MyMiX driver app:</h3>
              <div>Отчеты о достигнутых показателях и управление задачами</div>
            </div>
            <div>
              <h2>НОВЫЕ ОСОБЕННОСТИ</h2>
              <img src={imageFeatures02} alt="MyMiX Tracking" />
              <h3>MyMiX Tracking:</h3>
              <div>Отслеживание устройств, фиксация событий и обратная связь с водителем в режиме реального времени.</div>
            </div>
            <div>
              <img src={imageFeatures03} alt="Fleet Management" />
              <h3>
                Портал для флит-менеджера в веб-интерфейсе и мобильном приложении 
              </h3>
            </div>
          </FeaturesWrapper>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixTrackingStyled>
  );
}

export default MixTracking;
