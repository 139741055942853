import React from 'react';
import { NavLink } from 'react-router-dom';
import { SolutionBlock, SolutionsStyled, SolutionsWrapper, SolutionTitle } from './Solutions.styled';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import solutionsBg from '../../assets/images/solution/background.png';
import solution01 from '../../assets/images/solution/adas.jpg';
import solution02 from '../../assets/images/solution/dms.jpg';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Solutions(): JSX.Element {
  return (
    <SolutionsStyled>
      <SEO
        title="RGRauto | Решения"
      />
      <Section style={{ paddingTop: '0' }} backgroundImage={solutionsBg}>
        <Container>
          <PageTitle>Решения</PageTitle>
          <SolutionsWrapper>
            <SolutionBlock>
              <NavLink exact to="/mobileye">
                <img src={solution01} alt="mobileye" />
                <SolutionTitle>
                  Передовые системы помощи водителю (ADAS)
                </SolutionTitle>
              </NavLink>
            </SolutionBlock>
            <SolutionBlock>
              <NavLink exact to="/driver-monitoring-system">
                <img src={solution02} alt="driver-monitoring-system" />
                <SolutionTitle>
                  Системы контроля за состоянием водителя (DMS)
                </SolutionTitle>
              </NavLink>
            </SolutionBlock>
            <SolutionBlock>
              <NavLink exact to="/fleet-telematics">
                <SolutionTitle>
                  Телематика и системы управления безопасностью автопарка
                </SolutionTitle>
              </NavLink>
            </SolutionBlock>
          </SolutionsWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Мы с гордостью обслуживаем частные малые и средние автопарки, а также крупные корпоративные парки.</SectionTitle>
          <CustomersBlock />
        </Container>
      </Section>
    </SolutionsStyled>
  );
}

export default Solutions;
