import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from "../../lib/scrollLock";
import useScript from "../../hoc/useScript";
import '../../assets/styles/search.scss';

const SearchModal = NiceModal.create(() => {
  const modal = useModal();
  useScript("https://cse.google.com/cse.js?cx=ee9aa274c7752ad14", true)
  return (
    <Modal
      title="Поиск"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <div className="gcse-search"/>
    </Modal>
  );
});

export default SearchModal;
