import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import {
  DevicesSection,
  DeviceBlocksWrapper,
  Mobileye8ConnectStyled,
  NumbersBlockWrapper,
  FeatureCardsWrapper,
  BackgroundImage,
  ImagesSection,
} from './Mobileye8Connect.styled';
import Container from '../../components/Container/Container';
import Header from '../../components/Header/Header';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import Card from '../../components/Card/Card';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import Section from '../../components/Section/Section';
import AlertsSwitcher from '../../components/AlertsSwitcher/AlertsSwitcher';
import DeviceBlock from '../../components/DeviceBlock/DeviceBlock';
import NumberBlock from '../../components/NumberBlock/NumberBlock';
import { DeviceImageBorderType } from '../../components/DeviceBlock/DeviceBlock.types';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import headerBackground from '../../assets/images/mobileye8/header.jpg';
import device01 from '../../assets/images/mobileye8/retrofit-01.png';
import device02 from '../../assets/images/mobileye8/retrofit-02.png';
import device03 from '../../assets/images/mobileye8/retrofit-03.png';
import videoPreview from '../../assets/images/mobileye8/video.png';
import numberSectionBg from '../../assets/images/mobileye8/number-section.png';
import percent01 from '../../assets/images/mobileye/percent1.png';
import percent02 from '../../assets/images/mobileye/percent2.png';
import percent03 from '../../assets/images/mobileye/percent3.png';
import fms01 from '../../assets/images/mobileye/fms-1-b.png';
import fms01Hover from '../../assets/images/mobileye/fms-1-w.png';
import fms02 from '../../assets/images/mobileye/fms-2-b.png';
import fms02Hover from '../../assets/images/mobileye/fms-2-w.png';
import fms03 from '../../assets/images/mobileye/fms-3-b.png';
import fms03Hover from '../../assets/images/mobileye/fms-3-w.png';
import fms04 from '../../assets/images/mobileye/fms-4-b.png';
import fms04Hover from '../../assets/images/mobileye/fms-4-w.png';
import fms05 from '../../assets/images/mobileye/fms-5-b.png';
import fms05Hover from '../../assets/images/mobileye/fms-5-w.png';
import fms06 from '../../assets/images/mobileye/fms-6-b.png';
import fms06Hover from '../../assets/images/mobileye/fms-6-w.png';
import imageSectionLeft from '../../assets/images/mobileye8/left.png';
import imageSectionRight from '../../assets/images/mobileye8/right.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Mobileye8Connect(): JSX.Element {
  return (
    <Mobileye8ConnectStyled>
      <SEO
        title="RGRauto | Mobileye 8 Connect"
      />
      <Header
        subtitle="Повысьте безопасность автопарка сегодня"
        title={
          <>
            Mobileye 8 Connect<sup>™</sup>
          </>
        }
        description="Система предотвращения лобовых столкновений с улучшенными возможностями системы помощи водителю (ADAS), подключением к спутниковому мониторингу и полезным анализом данных."
        backgroundImage={headerBackground}
      />

      <Section backgroundColor="#F5F5F5">
        <Container>
          <SectionTitle>Предупреждения, спасающие жизни</SectionTitle>
          <AlertsSwitcher />
        </Container>
      </Section>

      <DevicesSection>
        <Container>
          <DeviceBlocksWrapper>
            <DeviceBlock
              name="The forward-facing camera"
              image={device01}
              borderType={DeviceImageBorderType.Round}
              number={1}
              description={
                <p>
                  Камера, смотрящая вперёд, крепится в салоне на лобовом стекле позади зеркала дальнего вида, постоянно сканирует (анализирует?) дорогу и при необходимости подаёт звуковые сигналы. Блок камеры содержит камеру, чип EyeQ и динамик.
                </p>
              }
            />
            <DeviceBlock
              name="Дисплей EyeWatch™"
              image={device02}
              borderType={DeviceImageBorderType.Circle}
              number={2}
              description={
                <p>Дисплей EyeWatch используется для визуальных оповещений и устанавливается в нижнем углу лобового стекла.</p>
              }
            />
            <DeviceBlock
              name="Блок GPS"
              image={device03}
              borderType={DeviceImageBorderType.Circle}
              number={3}
              description={<p>Блок GPS устанавливается в нижнем углу лобового стекла.</p>}
            />
          </DeviceBlocksWrapper>
        </Container>
      </DevicesSection>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="JPGUA9uKjJg" preview={videoPreview} />
        </Container>
      </Section>

      <Section backgroundImage={numberSectionBg}>
        <Container>
          <NumbersBlockWrapper>
            <NumberBlock icon={percent01} number={73} percents description="Сокращение затрат после столкновений" />
            <NumberBlock icon={percent02} number={91} percents description="Уменьшение затрат, связанных со столкновением" />
            <NumberBlock
              icon={percent03}
              number={97}
              percents
              description="Снижение числа страховых случаев, связанных с столкновениями"
            />
          </NumbersBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>Системы управления автопарками и функции отчётности</SectionTitle>
          <FeatureCardsWrapper>
            <FeatureCard
              icons={{
                main: fms01,
                hover: fms01Hover,
              }}
              title="Предотвратите столкновения"
              description={
                "Mobileye помогает автопаркам избегать столкновений, а не доказывать вину. Предотвращайте травмы ваших сотрудников с Системой помощи водителю Mobileye."
              }
            />
            <FeatureCard
              icons={{
                main: fms02,
                hover: fms02Hover,
              }}
              title="Улучшайте стиль вождения"
              description="Управляя автомобилем с установленной (оборудованной?) системой предотвращений аварий Mobileye (системой помощи водителю Mobileye?), водители начинают адаптироваться к более безопасным привычкам вождения."
            />
            <FeatureCard
              icons={{
                main: fms03,
                hover: fms03Hover,
              }}
              title="Доступно и экономически выгодно"
              description="Снижение аварийности с системой Mobileye поможет сэкономить деньги на ремонте и последующих страховых взносах."
            />
            <FeatureCard
              icons={{
                main: fms04,
                hover: fms04Hover,
              }}
              title="Подходит для любых автомобилей"
              description="Система Mobileye может быть установлена на любой автомобиль, вне зависимости от производителя, модели и года выпуска. Оснастите ваш существующий автопарк системой предотвращения столкновений Mobileye (системой помощи водителю?), без необходимости приобретать новый автомобиль уже со встроенной системой."
            />
            <FeatureCard
              icons={{
                main: fms05,
                hover: fms05Hover,
              }}
              title="Получайте отчеты и оповещения в реальном времени"
              description={
                "В то время как Mobileye помогает водителям, вы как флит-менеджер , получаете отчеты и оповещения об опасном вождении и нарушениях в реальном времени. Получайте своевременные отчеты, статистику, рейтинг водителей и рекомендации по улучшению вождения."
              }
            />
            <FeatureCard
              icons={{
                main: fms06,
                hover: fms06Hover,
              }}
              title={'Бесшовная интеграция с телематикой\n'}
              description={
                "Если в вашем автопарке уже установлена телематическая система, мы поможем вам интегрировать её с Mobileye, так что в дополнение к стандартным оповещениям телематики, вы получите важную информацию с видео-сенсора Mobileye. Эта комплексная информация жизненно-необходима для современных автопарков."
              }
            />
          </FeatureCardsWrapper>
        </Container>
      </Section>

      <ImagesSection>
        <BackgroundImage image={imageSectionLeft} />
        <BackgroundImage image={imageSectionRight} />
      </ImagesSection>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запроситьна пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее 
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </Mobileye8ConnectStyled>
  );
}

export default Mobileye8Connect;
