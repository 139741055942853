import React from 'react';
import headerBackground from '../../assets/images/mix-fleet-manager/header.jpg';
import Header from '../../components/Header/Header';
import { AppTruckWrapper, CompliantBlock, MixFleetManagerStyled } from './MixFleetManager.styled';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import backgroundMap from '../../assets/images/mix-fleet-manager/map.png';
import backgroundCompliant from '../../assets/images/background/about-us-center.png';
import imageFleetManagerDescription from '../../assets/images/mix-fleet-manager/about.jpg';
import imageTruck from '../../assets/images/mix-fleet-manager/truck.png';
import imageComputer from '../../assets/images/mix-fleet-manager/pc.png';
import imageELD from '../../assets/images/mix-fleet-manager/eld.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function MixFleetManager(): JSX.Element {
  return (
    <MixFleetManagerStyled>
      <SEO
        title="RGRauto | MiX Fleet Manager"
      />
      <Header
        title={
          <>
            MiX Fleet Manager™
          </>
        }
        description="MiX Fleet Manager™ предлагает руководителям автопарков неограниченный доступ к информации об их транспортных средствах и водителях, а также множество функций, инструментов и отчетов, помогающих максимизировать окупаемость инвестиций."
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={imageFleetManagerDescription}
            imagePosition="left"
            imageWidth={534}
            style={{ padding: '8px 16px' }}
          >
            <p style={{ fontSize: '16px', padding: '0 16px' }}>
              Решения для управления автопарком, которые предлагают руководителям автопарка неограниченный доступ к информации об их транспортных средствах и водителях, а также множество функций, инструментов и отчетов, помогающих максимизировать окупаемость инвестиций.
            </p>
            <p style={{ fontSize: '16px', padding: '0 16px' }}>
              Решение включает в себя сложный бортовой компьютер, который собирает и передает ценные данные о транспортном средстве и водителе. Эти данные хранятся в одном из нескольких центров обработки данных высшего уровня, и информация доступна онлайн или через мобильное приложение (Android или iOS).
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section backgroundColor="#11357E" backgroundImage={backgroundMap} style={{ backgroundSize: 'contain' }}>
        <Container>
          <SectionDescription>
            <p style={{ color: '#ffffff' }}>
              С помощью приложения MyMiX Tracking™ вы можете следить за временными транспортными средствами и подрядчиками, а также отслеживать рискованное поведение водителей — установка оборудования не требуется!
            </p>
          </SectionDescription>
          <AppTruckWrapper>
            <div>
              <img src={imageTruck} height="400px" alt="imageTruck" />
            </div>
            <div>
              <img src={imageComputer} height="400px" alt="imageComputer" />
            </div>
          </AppTruckWrapper>
        </Container>
      </Section>

      <Section style={{ paddingBottom: '0' }}>
        <Container>
          <SectionTitle>Электронное устройство регистрации (ELD)</SectionTitle>
          <p>
            ELD (электронное устройство регистрации) MiX Telematics было специально разработано для управления и поддержания соответствия вашего автопарка правилу часов работы (HOS) посредством электронного отслеживания рабочего статуса водителя (RODS) в качестве эффективной замены устаревшим бумажным бортовым журналам.
          </p>
          <p style={{ textAlign: 'center' }}>
            <img src={imageELD} alt="ELD" height="517px" style={{ width: '100%', height: 'auto', maxWidth: '900px' }} />
          </p>
        </Container>
      </Section>

      <Section backgroundImage={backgroundCompliant} style={{ padding: '100px 0', backgroundSize: 'contain' }}>
        <Container>
          <CompliantBlock>
            <SectionTitle>Соответствуете ли вы требованиям ELD Mandate?</SectionTitle>
            <p>
              18 декабря 2017 года вступил в силу закон ELD Mandate, введенный Федеральной администрацией безопасности автоперевозчиков (FMCSA). (FMCSA). Согласно этому требованию, водители коммерческих автомобилей должны отслеживать свою историю дежурств (Record of Duty Status, RODS) в электронном виде с помощью совместимых электронных регистраторов. (RODS) в электронном виде с помощью соответствующих электронных регистрационных устройств (ELD), а не бумажных журналов. Действуйте сейчас, чтобы убедиться, что ваш автопарк полностью соответствует требованиям к окончательному сроку - 16 декабря 2019 года!
            </p>
          </CompliantBlock>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MixFleetManagerStyled>
  );
}

export default MixFleetManager;
