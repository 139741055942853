import React from 'react';
import {
  ButtonBlockContent,
  ButtonBlockImage,
  ButtonBlockWrapper,
  FaceBlockContent,
  FaceBlockWrapper,
  GreenroadVideosenseStyled,
  PhoneBlockBackground,
  PhoneBlockContentWrapper,
  PhoneBlockWrapper,
  RoadBlock,
} from './GreenroadVideosense.styled';
import headerBackground from '../../assets/images/videosense/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import SectionDescription from '../../components/SectionDescription/SectionDescription';
import Card from '../../components/Card/Card';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import imageVideosenseDescription from '../../assets/images/videosense/video-sense.jpg';
import imagePhone from '../../assets/images/videosense/phone.png';
import imageButton from '../../assets/images/videosense/engine-start-button.png';
import imageButtonDescription from '../../assets/images/videosense/engine-starting.jpg';
import imageFaceIcon from '../../assets/images/videosense/record-icon.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GreenroadVideosense(): JSX.Element {
  return (
    <GreenroadVideosenseStyled>
      <SEO
        title="RGRauto | GreenRoad Videosense"
      />
      <Header
        title={
          <>
            Объективная обратная связь
            <br />
            Обратная связь в реальном времени и обучение
          </>
        }
        description={
          <>
            Избавляет от вредных привычек и опасного вождения
            <br />
            Устранение вредных привычек и рискованного поведения за рулем
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={imageVideosenseDescription}
            imagePosition="right"
            imageWidth={518}
            style={{ padding: '0 16px' }}
          >
            <p>
              Умные камеры и ИИ (Искусственный Интеллект) решения VideoSense™ работают вместе для распознавания усталости, отвлечения внимания, контроля использования ремня безопасности и других действий водителя. Небезопасные состояния и действия водителей являются сигналом для оповещения водителей и флит-менеджеров в реальном времени.
            </p>
            <p>
              Это позволяет оперативно вмешиваться и изменять поведение для предотвращения катастроф. Камеры GreenRoad также предоставляют умное подключение для поддержки дополнительных приложений управления автопарком.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section style={{ padding: '0' }}>
        <Container>
          <PhoneBlockWrapper>
            <PhoneBlockContentWrapper>
              <div>
                <img src={imagePhone} alt="Phone pic" width="190" />
              </div>
              <div>
                <h2>Простая метрика = Большое влияние на безопасность вождения</h2>
                <div>
                  <p>
                    <strong>
                      VideoSense™ использует установленные в автомобиле камеры для автоматической записи событий безопасности, которые после фиксируются в телематической платформе GreenRoad, что дает вам (флит менеджеру?) подробные данные для анализа причинно-следственных связей.
                    </strong>
                  </p>
                  <p>
                    Программное обеспечение добавляет еще одно измерение к данным о поведении водителя GreenRoad и показателям безопасности, а также служит решением DIM (отслеживание ухудшения состояния водителя), чтобы обеспечить упреждающее вмешательство в случае усталости или отвлечения внимания водителя.
                  </p>
                </div>
              </div>
            </PhoneBlockContentWrapper>
            <PhoneBlockBackground />
          </PhoneBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <FaceBlockWrapper>
            <FaceBlockContent>
              <div>
                <img src={imageFaceIcon} alt="icon" height="120px" />
              </div>
              <div>
                <p>
                  Решение VideoSense™ автоматически записывает критические секунды непосредственно до, во время и после каждого события, предоставляя менеджерам автопарка доступ к видео по единой ссылке непосредственно из записи события на платформе телематики.
                </p>
              </div>
            </FaceBlockContent>
          </FaceBlockWrapper>
          <p>
            Это позволяет провести прямую корреляцию между внешними факторами на дороге и поведением водителя, что допускает возможность оправдания вашего водителя и/или показывает, какие события могли были быть скорректированы водителем. Эти видео являются неоценимыми обучающими материалами.
          </p>
        </Container>
      </Section>

      <Section style={{ padding: '0' }}>
        <Container>
          <SectionDescription>
            <p>
              В дополнение, данная технология способна распознавать усталость, отвлечение внимания, ремень безопасности, курение и т.д., запуская автоматические оповещения, которые предоставляются непосредственно водителю и их менеджерам.
            </p>
          </SectionDescription>
        </Container>
        <RoadBlock />
        <Container>
          <ButtonBlockWrapper>
            <ButtonBlockImage src={imageButton} alt="Videosense button" />
            <ButtonBlockContent>
              Благодаря тому, что камеры VideoSense™ работают постоянно, их записи могут быть использованы для автоматических оповещений, основанных на поведении водителя, позволяя вмешиваться в реальном времени при наличии признаков отвлечения внимания или усталости.
            </ButtonBlockContent>
          </ButtonBlockWrapper>
          <BorderBlock image={imageButtonDescription} imagePosition="left" imageWidth={530} style={{ padding: '16px' }}>
            <p style={{ padding: '0 16px' }}>
              Камеры могут быть активированы для обеспечения прямой трансляции в режиме реального времени с транспортного средства, включая ситуации, когда водитель самостоятельно нажимает незаметную Кнопку Водителя, которую водители могут использовать в случаях опасности.
            </p>
            <p style={{ padding: '0 16px' }}>
              В дополнение, программное обеспечение может поддерживать функции контроля кнопки «Пуск» для дополнительной защиты в экстренных ситуациях
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GreenroadVideosenseStyled>
  );
}

export default GreenroadVideosense;
