import React from 'react';
import Container from '../../components/Container/Container';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { FeatureCardsWrapper, NumbersBlockWrapper } from '../Mobileye8Connect/Mobileye8Connect.styled';
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import fms01 from '../../assets/images/mobileye/fms-1-b.png';
import fms01Hover from '../../assets/images/mobileye/fms-1-w.png';
import fms02 from '../../assets/images/mobileye/fms-2-b.png';
import fms02Hover from '../../assets/images/mobileye/fms-2-w.png';
import fms03 from '../../assets/images/mobileye/fms-3-b.png';
import fms03Hover from '../../assets/images/mobileye/fms-3-w.png';
import fms04 from '../../assets/images/mobileye/fms-4-b.png';
import fms04Hover from '../../assets/images/mobileye/fms-4-w.png';
import fms05 from '../../assets/images/mobileye/fms-5-b.png';
import fms05Hover from '../../assets/images/mobileye/fms-5-w.png';
import fms06 from '../../assets/images/mobileye/fms-6-b.png';
import fms06Hover from '../../assets/images/mobileye/fms-6-w.png';
import Section from '../../components/Section/Section';
import numberSectionBg from '../../assets/images/mobileye8/number-section.png';
import NumberBlock from '../../components/NumberBlock/NumberBlock';
import percent01 from '../../assets/images/mobileye/percent1.png';
import percent02 from '../../assets/images/mobileye/percent2.png';
import percent03 from '../../assets/images/mobileye/percent3.png';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import {
  FramedContent,
  FramedContentFrame,
  FramedContentWrapper,
  HeaderBackground,
  MobileyeStyled,
  VehiclesSection,
  VehiclesText,
  VehiclesTitle,
} from './Mobileye.styled';
import mobileyeDevice from '../../assets/images/mobileye/mobileye-device.png';
import { QuoteHorizontal } from '../../components/Quote/Quote.styled';
import ProductCard from '../../components/ProductCard/ProductCard';
import mobileye8Image from '../../assets/images/mobileye/mobileye8.jpg';
import mobileyeShieldImage from '../../assets/images/mobileye/mobileye-shield.jpg';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Mobileye(): JSX.Element {
  return (
    <MobileyeStyled>
      <SEO
        title="RGRauto | Mobileye"
      />
      <HeaderBackground />
      <Section backgroundColor="transparent" style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle variant="inverted">Mobileye</PageTitle>
          <p style={{ color: '#FFFFFF', marginBottom: '36px' }}>
            Технология предотвращений аварий от Mobileye, основанная на компьютерном зрении (искусственном интеллекте? видеоаналитике?), помогает водителям повысить безопасность вождения. 
          </p>
          <Card style={{ overflow: 'hidden' }}>
            <img src={mobileyeDevice} height={360} style={{ float: 'right' }} alt="Mobileye" />
            <QuoteHorizontal>
              <p>
                Точно также, когда мы водим, используя наше зрение чтобы просканировать дорожную обстановку, оценить насколько далеко находимся от препятствий и какую угрозу они несут – видео сенсор может таким же образом сканировать дорожную обстановку и считывать ее намного дальше и при этом система никогда не устанет и не отвлечется.
              </p>
            </QuoteHorizontal>
            <p>
              Ключевой элемент в системе помощи водителю Mobileye – это возможность имитировать то, как люди видят дорожное полотно (дорогу?). Используя ИИ, чип Mobileye’s EyeQ® способен идентифицировать возможные угрозы и предупредить водителя об опасной ситуации в реальном времени, предоставляя ему достаточно времени чтобы совершить маневр и избежать аварии (ДТП?).
            </p>
            <p>
              Автопарки по всему миру отметили значительное снижение количества аварий и связанных с ними расходов благодаря Mobileye. Мы можем помочь вашему автопарку достичь того же. Помогите своим водителям предотвратить столкновения  с помощью использования системы Mobileye, предоставляя им оповещения в режиме реального времени за несколько секунд до того, как может произойти потенциальное столкновение.
            </p>

            <div style={{ margin: '0 -80px' }}>
              <FramedContentWrapper style={{ margin: '48px 0 24px' }}>
                <FramedContentFrame />
                <FramedContent>
                  <div>
                    Система помощи водителю Mobileye доступна либо с одной камерой, направленной вперёд (прямо на дорогу?), подходящей для практически любого автомобиля, либо с несколькими камерами, разработанное для большегрузных коммерческих автомобилей с опасными слепыми зонами.
                  </div>
                </FramedContent>
              </FramedContentWrapper>
            </div>
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <ProductCard
            title="Mobileye 8 Connect"
            description="для автопарков"
            image={mobileye8Image}
            imageAtLeft
            links={[
              {
                title: 'Смотреть видео',
                description: 'Mobileye 8 Connect',
                url: 'JPGUA9uKjJg',
                type: 'video',
              },
              {
                title: 'Далее',
                description: 'Читать далее о Mobileye 8 Connect',
                url: '/mobileye/mobileye-8-connect',
                type: 'nav',
              },
              {
                title: 'Свяжитесь с нами',
                url: '/contact',
                type: 'nav',
              },
            ]}
          />
          {/*<ProductCard*/}
          {/*  title="Mobileye Shield Plus"*/}
          {/*  description="for large vehicles"*/}
          {/*  image={mobileyeShieldImage}*/}
          {/*  imageAtLeft={false}*/}
          {/*  links={[*/}
          {/*    {*/}
          {/*      title: 'Watch Video',*/}
          {/*      description: 'Mobileye Shield Plus',*/}
          {/*      url: 'uIvtK5Sb1jk',*/}
          {/*      type: 'video',*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: 'Read More',*/}
          {/*      description: 'Read More about Mobileye Shield Plus',*/}
          {/*      url: '/mobileye/mobileye-shield',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*    {*/}
          {/*      title: 'Contact Us',*/}
          {/*      url: '/contact',*/}
          {/*      type: 'nav',*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>FMS и функции отчетности</SectionTitle>
          <FeatureCardsWrapper>
            <FeatureCard
              icons={{
                main: fms01,
                hover: fms01Hover,
              }}
              title="Предотвратите столкновения"
              description={
                "Mobileye помогает автопаркам избегать столкновений, а не доказывать вину. Предотвращайте травмы ваших сотрудников с Системой помощи водителю Mobileye."
              }
            />
            <FeatureCard
              icons={{
                main: fms02,
                hover: fms02Hover,
              }}
              title="Улучшайте стиль вождения"
              description="Управляя автомобилем с установленной (оборудованной?) системой предотвращений аварий Mobileye (системой помощи водителю Mobileye?), водители начинают адаптироваться к более безопасным привычкам вождения."
            />
            <FeatureCard
              icons={{
                main: fms03,
                hover: fms03Hover,
              }}
              title="Доступно и экономически выгодно"
              description="Снижение аварийности с системой Mobileye поможет сэкономить деньги на ремонте и последующих страховых взносах."
            />
            <FeatureCard
              icons={{
                main: fms04,
                hover: fms04Hover,
              }}
              title="Подходит для любых автомобилей"
              description="Система Mobileye может быть установлена на любой автомобиль, вне зависимости от производителя, модели и года выпуска. Оснастите ваш существующий автопарк системой предотвращения столкновений Mobileye (системой помощи водителю?), без необходимости приобретать новый автомобиль уже со встроенной системой."
            />
            <FeatureCard
              icons={{
                main: fms05,
                hover: fms05Hover,
              }}
              title="Получайте отчеты и оповещения в реальном времени"
              description={
                "В то время как Mobileye помогает водителям, вы как флит-менеджер , получаете отчеты и оповещения об опасном вождении и нарушениях в реальном времени. Получайте своевременные отчеты, статистику, рейтинг водителей и рекомендации по улучшению вождения."
              }
            />
            <FeatureCard
              icons={{
                main: fms06,
                hover: fms06Hover,
              }}
              title={'Бесшовная интеграция с телематикой\n'}
              description={
                "Если в вашем автопарке уже установлена телематическая система, мы поможем вам интегрировать её с Mobileye, так что в дополнение к стандартным оповещениям телематики, вы получите важную информацию с видео-сенсора Mobileye. Эта комплексная информация жизненно-необходима для современных автопарков."
              }
            />
          </FeatureCardsWrapper>
        </Container>
      </Section>

      <VehiclesSection>
        <Container>
          <VehiclesTitle>
            <div>65M+</div>
            <div>
              Автомобили, оснащенные технологией Mobileye<br />
              Mobileye technology
            </div>
          </VehiclesTitle>
          <VehiclesText>
            Снижение количества ДТП с помощью оповещений Mobileye сохраняет деньги на ремонте и последующих страховых выплатах. Обычно, менеджеры автопарков (флит-менеджеры?) видят возврат инвестиций в течение 12 месяцев.
          </VehiclesText>
        </Container>
      </VehiclesSection>

      <Section backgroundImage={numberSectionBg}>
        <Container>
          <NumbersBlockWrapper>
            <NumberBlock icon={percent01} number={73} percents description="Сокращение затрат после столкновений" />
            <NumberBlock icon={percent02} number={91} percents description="Уменьшение затрат, связанных со столкновением" />
            <NumberBlock
              icon={percent03}
              number={97}
              percents
              description="Снижение числа страховых случаев, связанных со столкновениями"
            />
          </NumbersBlockWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </MobileyeStyled>
  );
}

export default Mobileye;
