import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import { enableBodyScroll } from '../../lib/scrollLock';
import { ModalLead, ModalList, ModalMainImage, ModalMainWrapper } from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/road-facing-camera.png';

const MixVisionModal03 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Камера внутри салона"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        Камера, смотрящая на дорогу, распознает события, включая возможные столкновения и смену полосы. 
        Камера внутри салона записывает изображение и звук в режиме реального времени как видеорегистратор (без распознавания событий с помощью искусственного интеллекта).

      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="In-Cab Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Крепится к лобовому стеклу</li>
            <li>Цветное изображение с широкоугольным обзором салона</li>
            <li>
              Встроенные инфракрасные светодиоды с подсветкой до 3х метров для записи видео внутри салона в условиях низкой освещенности или ночью
            </li>
            <li>Встроенный микрофон для записи звука 920P @ 10 кадров в секунду</li>
          </ModalList>
        </div>
      </ModalMainWrapper>
    </Modal>
  );
});

export default MixVisionModal03;
