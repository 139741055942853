import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { CipiaStyled } from './Cipia.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/cipia.svg';
import companyImage from '../../assets/images/companies/cipia.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Cipia(): JSX.Element {
  return (
    <CipiaStyled>
      <SEO
        title="RGRauto | Сipia"
      />
      <Container>
        <PageTitle>Сipia</PageTitle>
        <CompanyCard
          name="Cipia"
          logo={companyLogo}
          image={companyImage}
          buttons={[
            {
              title: 'Fleet Sense FS10',
              url: '/cipia/fleet-sense-fs10',
            },
          ]}
        >
          <p>
            Сipia
          </p>
          <p>
            Fleet Sense FS10
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Записаться на пилот</SectionTitle>
          <CardText>
            Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </CipiaStyled>
  );
}

export default Cipia;
