import React from 'react';
import { NavLink } from 'react-router-dom';
import { SitemapColumn, SitemapStyled, SitemapUl, SitemapWrapper } from './Sitemap.styled';
import sitemapList from '../../data/sitemapList';
import Container from '../../components/Container/Container';
import { PageTitle } from '../../components/PageTitle/PageTitle.styled';
import { SitemapLink } from './Sitemap.types';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function renderSitemap(link: SitemapLink, level = 1): JSX.Element {
  const nextLevel = level + 1;
  const key = Math.random();
  return (
    <React.Fragment key={key}>
      {link.links ? (
        <li>
          <NavLink exact to={link.url}>
            {link.title}
          </NavLink>
          <ul data-level={`level-${level}`}>{link.links.map((sublink) => renderSitemap(sublink, nextLevel))}</ul>
        </li>
      ) : (
        <li>
          <NavLink to={link.url}>{link.title}</NavLink>
        </li>
      )}
    </React.Fragment>
  );
}

function Sitemap(): JSX.Element {
  return (
    <SitemapStyled>
      <SEO
        title="RGRauto | Sitemap"
      />
      <Container>
        <PageTitle variant="inverted">Карта сайта</PageTitle>
        <SitemapWrapper>
          <SitemapColumn>
            <SitemapUl>{renderSitemap(sitemapList[0])}</SitemapUl>
          </SitemapColumn>
          <SitemapColumn>
            <SitemapUl>{renderSitemap(sitemapList[1])}</SitemapUl>
          </SitemapColumn>
          <SitemapColumn>
            <SitemapUl>{renderSitemap(sitemapList[2])}</SitemapUl>
            <SitemapUl>{renderSitemap(sitemapList[3])}</SitemapUl>
            <SitemapUl>{renderSitemap(sitemapList[4])}</SitemapUl>
          </SitemapColumn>
        </SitemapWrapper>
      </Container>
    </SitemapStyled>
  );
}

export default Sitemap;
