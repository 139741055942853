import React from 'react';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import { CloudWiseStyled } from './CloudWise.styled';
import Container from '../../components/Container/Container';
import { CardText } from '../../components/Card/Card.styled';
import Card from '../../components/Card/Card';
import FormRequest from '../../components/Form/FormRequest';
import CompanyCard from '../../components/CompanyCard/CompanyCard';
import companyLogo from '../../assets/images/logo/cloud-wise.jpg';
import companyImage from '../../assets/images/companies/cloud-wise.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CloudWise(): JSX.Element {
  return (
    <CloudWiseStyled>
      <SEO
        title="RGRauto | Cloud-Wise"
      />
      <Container>
        <PageTitle>О Cloud-wise</PageTitle>
        <CompanyCard
          name="Cloud-wise"
          logo={companyLogo}
          image={companyImage}
          buttons={[
            {
              title: 'Про I-STICKER',
              url: '/cloud-wise/i-sticker',
            },
          ]}
        >
          <p>
            Мы уверены, что информация о местонахождении автомобиля и поведении водителя в режиме реального времени может повысить безопасность дорожного движения.
          </p>
          <p>
            Однако, для реализации потенциала требуется удобная, простая во внедрении, универсальная система, которая преодолевает недостатки существующих решений. Существующие классические решения на рынке требуют сложного процесса внедрения и сопряжены с огромными затратами на установку, трудностями первоначального внедрения и дальнейшей эксплуатацией как по временным, так и по финансовым затратам. Помимо этого, прямое подключение к автомобилю может приводить к разрядке аккумулятора, кибератакам, снятию автомобиля с гарантии и общим излишним усилиям и рискам.
          </p>
          <p>
            Компания Cloud-Wise была создана с целью разработки системы нового поколения, которая устраняет вышеназванные препятствия и позволяет собирать данные о транспортных средствах безопасным, простым и экономичным способом.
          </p>
        </CompanyCard>
        <Card>
          <SectionTitle>Записаться на пилот</SectionTitle>
          <CardText>
            Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
          </CardText>
          <FormRequest />
        </Card>
      </Container>
    </CloudWiseStyled>
  );
}

export default CloudWise;
