import React from 'react';
import { CipiaFleetSenseStyled, Function, FunctionsBlock, FunctionsWrapper } from './CipiaFleetSense.styled';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Card from '../../components/Card/Card';
import { QuoteHorizontal } from '../../components/Quote/Quote.styled';
import Section from '../../components/Section/Section';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import headerBackground from '../../assets/images/fleet-fs10/header.jpg';
import fleetDevice from '../../assets/images/fleet-fs10/fs10.png';
import icon01 from '../../assets/images/fleet-fs10/icon1.png';
import icon02 from '../../assets/images/fleet-fs10/icon2.png';
import icon03 from '../../assets/images/fleet-fs10/icon3.png';
import icon04 from '../../assets/images/fleet-fs10/icon4.png';
import icon05 from '../../assets/images/fleet-fs10/icon5.png';
import icon06 from '../../assets/images/fleet-fs10/icon6.png';
import icon07 from '../../assets/images/fleet-fs10/icon7.png';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CipiaFleetSense(): JSX.Element {
  return (
    <CipiaFleetSenseStyled>
      <SEO
        title="RGRauto | Fleet sense FS10"
      />
      <Header
        title="Fleet sense FS10"
        description={
          <>
            Cipia предоставляет высокотехнологичную систему мониторинга водителя Fleet sense, разработанную для автопарков и поставщиков телематики.
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <Card>
            <img src={fleetDevice} height={340} style={{ float: 'right', marginRight: '-72px' }} alt="Mobileye" />
            <QuoteHorizontal>
              <p>
                Используя компьютерное зрение, искусственный интеллект (ИИ), а также ИК-сенсор, Fleet sense отслеживает расположение головы водителя, век и направление взгляда для того, чтобы предоставлять в режиме реального времени оценку состояния водителя (признаки усталости и сонливость, отвлечение внимания от дороги), действий (курение, ношение маски, непристегнутый ремень безопасности, использование телефона, закрытие камеры и вандализм) и идентификации (распознавание зарегистрированных водителей по лицу).
              </p>
            </QuoteHorizontal>
            <p>
              Определение состояния водителя, действий, идентификации водителя и статуса камеры создает функционал для сохранения жизней и инструмент снижения издержек для автопарков и поставщиков телематики. Водитель получает оповещения в реальном времени тогда, когда фиксируется сонливость, отвлечение внимания или его опасные действия, в то время как менеджер автопарка получает обработанные оповещения в реальном времени исходя из своих потребностей, что позволяет провести оптимизацию управления автопарком и увеличить эффективность диспетчера.
            </p>
          </Card>
        </Container>
      </Section>

      <Section>
        <Container>
          <FunctionsBlock>
            <FunctionsWrapper>
              <Function>
                <img src={icon01} alt="FS10 Drowsiness" />
                <p>Сонливость</p>
              </Function>
              <Function>
                <img src={icon02} alt="FS10 Holding Phone" />
                <p>Использование телефона</p>
              </Function>
              <Function>
                <img src={icon03} alt="FS10 Distraction" />
                <p>Отвлечение внимания</p>
              </Function>
              <Function>
                <img src={icon04} alt="FS10 Driver ID" />
                <p>Идентификация водителя по лицу</p>
              </Function>
              <Function>
                <img src={icon05} alt="FS10 Smoking" />
                <p>Курение</p>
              </Function>
              <Function>
                <img src={icon06} alt="FS10 Camera Status" />
                <p>Статус камеры</p>
              </Function>
              <Function>
                <img src={icon07} alt="FS10 Seatbelt" />
                <p>Ремень безопасности</p>
              </Function>
            </FunctionsWrapper>
          </FunctionsBlock>
        </Container>
      </Section>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="aGbXTCqMfk0" />
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </CipiaFleetSenseStyled>
  );
}

export default CipiaFleetSense;
