import React from 'react';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import headerBackground from '../../assets/images/go9/header.jpg';
import aboutImage from '../../assets/images/go9/about.png';
import go9Image from '../../assets/images/go9/go9.png';
import { GeotabGo9Styled } from './GeotabGo9.styled';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function GeotabGo9(): JSX.Element {
  return (
    <GeotabGo9Styled>
      <SEO
        title="RGRauto | Geotab GO9"
      />
      <Header
        title="Geotab"
        description={
          <>
            Устройство спутникового мониторинга за корпоративными автомобилями.
          </>
        }
        backgroundImage={headerBackground}
      />

      <Section>
        <Container>
          <BorderBlock
            image={aboutImage}
            imagePosition="left"
            imageWidth={640}
            imageOffset={{ left: -60 }}
            imageBackground={null}
          >
            <p>
              Geotab GO9 — это компактное устройство спутникового мониторинга за транспортными средствами, которое легко подключается непосредственно к порту OBDII вашего автомобиля или через адаптер для автомобилей, если данный вход в автомобиле не предусмотрен. Нет необходимости в антенне или наращивании проводов. Процесс установки быстрый, простой и не требует каких-либо специальных инструментов или помощи профессионального установщика.
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <BorderBlock image={go9Image} imagePosition="right" imageWidth={400} imageBackground="#FFFFFF">
            <p>
              GO9 RUGGED: прочное и надежное устройство GPS-слежения Суровые условия или внешняя установка не подходят для GO9 RUGGED.  (может наоборот подходит? предназначено?)Надежное телематическое устройство Geotab предназначено для автопарков с тяжелой, строительной дорожной и сельскохозяйственной техникой, прицепами с электроприводом и другими активами. Трекер использует ту же передовую технологию GPS, что и Geotab GO, но с дополнительным преимуществом, состоящим в том, что он имеет рейтинг IP68 и IP69K (международный стандарт защиты от проникновения твердых тел, пыли, контакта и воды).
            </p>
          </BorderBlock>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </GeotabGo9Styled>
  );
}

export default GeotabGo9;
