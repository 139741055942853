import React, { useState } from "react";
import { CookieBannerStyled, CookieBannerWrapper } from "./CookieBanner.styled";
import Container from "../Container/Container";
import { OutlinedButton } from "../../pages/Home/Home.styled";

const CookieBanner = (): JSX.Element => {
  const [isCookieAccept, setIsCookieAccept] = useState(false);

  const handleOkButton = () => {
    setIsCookieAccept(true);
    localStorage.setItem('isCookieAccept', 'true');
  }
  const showBanner =
    localStorage.getItem('isCookieAccept') !== 'true' &&
    isCookieAccept === false;

  return showBanner && (
    <CookieBannerStyled>
      <Container>
        <CookieBannerWrapper>
          <div>
            <p>На этом сайте используются файлы cookie. Мы используем файлы cookie для того, чтобы обеспечить наилучшие условия работы на нашем сайте.</p>
            <p>Если вы продолжаете пользоваться сайтом, мы будем считать, что вы согласны получать все файлы cookie на этом сайте.</p>
          </div>
          <div>
            <OutlinedButton as="button" onClick={handleOkButton}>
              Хорошо, спасибо
            </OutlinedButton>
          </div>
        </CookieBannerWrapper>
      </Container>
    </CookieBannerStyled>
  );
};

export default CookieBanner;