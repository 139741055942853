import React from 'react';
import { ContactsIcon, ContactsInfo, ContactsWrapper, MapImg } from './ContactsBlock.styled';
import emailIcon from '../../assets/icons/email.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import clockIcon from '../../assets/icons/clock.svg';
import mapImage from '../../assets/images/contact-us/map.jpg';

const ContactsBlock = (): JSX.Element => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={emailIcon} alt="Email" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <a href="mailto:sales@rgrauto.com">sales@rgrauto.com</a>
        </div>
        <div>
          <a href="mailto:support@rgrauto.com">support@rgrauto.ru</a>
        </div>
        <div>
          <a href="mailto:info@rgrauto.com">info@rgrauto.com</a>
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={phoneIcon} alt="Phone" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <a href="tel:+74957752424">+7-(495)-775-24-24</a>
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <ContactsWrapper>
      <ContactsIcon>
        <img src={clockIcon} alt="Time" />
      </ContactsIcon>
      <ContactsInfo>
        <div>
          <strong>Понедельник-Пятница:</strong> с 10:00 до 19:00
        </div>
      </ContactsInfo>
    </ContactsWrapper>
    <div style={{ marginTop: 'auto', marginBottom: '24px' }}>
      <p>
        <strong>
          108811, г. Москва, п. Московский, 
          д. Румянцево, стр.1 Бизнес-парк «Румянцево»
        </strong>
      </p>
      <div>
        <a href="https://g.page/rgrauto-inc?share" target="_blank" rel="noreferrer">
          <MapImg src={mapImage} alt="map" />
        </a>
      </div>
    </div>
  </div>
);

export default ContactsBlock;
