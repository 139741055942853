import React from 'react';
import { ContactStyled } from './Contact.styled';
import Container from '../../components/Container/Container';
import { PageTitle, SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import Section from '../../components/Section/Section';
import { CardDescription } from '../../components/Card/Card.styled';
import FormWithContacts from '../../components/Form/FormWithContacts';
import Card from '../../components/Card/Card';
import CustomersBlock from '../../components/CustomersBlock/CustomersBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function Contact(): JSX.Element {
  return (
    <ContactStyled>
      <SEO
        title="RGRauto | Contacts"
      />
      <Section style={{ paddingTop: '0' }}>
        <Container>
          <PageTitle>Связаться с нами</PageTitle>
          <p>У вас есть вопрос?</p>
          <Card>
            <CardDescription>
              Пожалуйста, заполните приведенную ниже форму, и мы <br/> 
              свяжемся с Вами в ближайшее время.
            </CardDescription>
            <FormWithContacts />
          </Card>
        </Container>
      </Section>

      <Section style={{ paddingTop: '0' }}>
        <Container>
          <SectionTitle>Мы с удовольствием обслуживаем как частные малые и средние автопарки, так и крупные корпоративные автопарки</SectionTitle>
          <CustomersBlock />
        </Container>
      </Section>
    </ContactStyled>
  );
}

export default Contact;
