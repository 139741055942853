import React from 'react';
import {
  AboutBlockImage,
  AboutBlockWrapper,
  CloudWiseIStickerStyled,
  EdgeWrapper,
  FeaturesWrapper,
  MainSection,
} from './CloudWiseISticker.styled';
import headerBackground from '../../assets/images/i-sticker/header.jpg';
import Header from '../../components/Header/Header';
import Section from '../../components/Section/Section';
import Container from '../../components/Container/Container';
import BorderBlock from '../../components/BorderBlock/BorderBlock';
import { SectionTitle } from '../../components/PageTitle/PageTitle.styled';
import FlipBlock from '../../components/FlipBlock/FlipBlock';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import imageDescriptionMain from '../../assets/images/i-sticker/indrive-img.jpg';
import imageSticker from '../../assets/images/i-sticker/i-sticker.png';
import imageAbout from '../../assets/images/i-sticker/about.png';
import imageFeature01 from '../../assets/images/i-sticker/features-square-1.svg';
import imageFeature02 from '../../assets/images/i-sticker/features-square-2.svg';
import imageFeature03 from '../../assets/images/i-sticker/features-square-3.svg';
import imageFeature04 from '../../assets/images/i-sticker/features-square-4.svg';
import imageFeature05 from '../../assets/images/i-sticker/features-square-5.svg';
import imageFeature06 from '../../assets/images/i-sticker/features-square-6.svg';
import imageFeature07 from '../../assets/images/i-sticker/features-square-7.svg';
import imageFeature08 from '../../assets/images/i-sticker/features-square-8.svg';
import imageFeature09 from '../../assets/images/i-sticker/features-square-9.svg';
import imageFeature10 from '../../assets/images/i-sticker/features-square-10.svg';
import imageEdge01 from '../../assets/images/i-sticker/edge-icon-1.svg';
import imageEdge02 from '../../assets/images/i-sticker/edge-icon-2.svg';
import imageEdge03 from '../../assets/images/i-sticker/edge-icon-3.svg';
import imageEdge04 from '../../assets/images/i-sticker/edge-icon-4.svg';
import imageEdge05 from '../../assets/images/i-sticker/edge-icon-5.svg';
import imageEdge06 from '../../assets/images/i-sticker/edge-icon-6.svg';
import imageEdge07 from '../../assets/images/i-sticker/edge-icon-7.svg';
import imageEdge08 from '../../assets/images/i-sticker/edge-icon-8.svg';
import Card from '../../components/Card/Card';
import { CardText } from '../../components/Card/Card.styled';
import FormRequest from '../../components/Form/FormRequest';
import EdgeBlock from '../../components/EdgeBlock/EdgeBlock';
// @ts-ignore
import SEO from '@americanexpress/react-seo';

function CloudWiseISticker(): JSX.Element {
  return (
    <CloudWiseIStickerStyled>
      <SEO
        title="RGRauto | I-Sticker"
      />
      <Header
        title="I-Sticker"
        description="I-Sticker от Cloud-Wise — это телематика нового поколения, которую очень просто внедрить и легко использовать."
        backgroundImage={headerBackground}
      />
      <MainSection>
        <Container>
          <BorderBlock image={imageDescriptionMain} imagePosition="left" imageWidth={544}>
            <p style={{ textAlign: 'center' }}>
              <img src={imageSticker} alt="I-Sticker" style={{ width: '100%' }} />
            </p>
            <p>
              I-Sticker - это новое поколение телематики, которое не требует установки, легко внедряется и очень удобно для пользователя.
            </p>
            <p>
              В отличие от других телематических решений, она не требует установки, поэтому не имеет накладных расходов и затрат на обслуживание. Это настоящая революция в телематической отрасли.
            </p>
            <p>
              Руководители автопарков знают, насколько важна телематика, но они также осознают неудобства, связанные с необходимостью установки этих устройств и их обслуживания в течение всего срока службы автомобиля.
            </p>
          </BorderBlock>
        </Container>
        <Container>
          <AboutBlockWrapper>
            <div>
              <p>
                Каждая компания, независимо от размера, которая управляет парком транспортных средств и имеет опыт работы с GPS-трекерами, ищет решение, которое избавит их от проводных или OBDII-устройств, требующих монтажа. Внедрение и обслуживание этих устройств стали ахиллесовой пятой современных телематических решений.
              </p>
              <p>
                Обладая более чем 20-летним опытом работы с крупными и малыми парками, мы прислушались к мнению наших клиентов и вместе с командой исследований и разработок в Cloud-wise представляем I-Sticker.
              </p>
              <p>
                Просто наклеил и поехал!
              </p>
            </div>
            <AboutBlockImage>
              <img src={imageAbout} width="465px" alt="About I-Sticker" />
            </AboutBlockImage>
          </AboutBlockWrapper>
        </Container>
      </MainSection>

      <Section backgroundColor="#1C2F75">
        <Container>
          <SectionTitle variant="inverted">Ключевые качества</SectionTitle>
          <FeaturesWrapper>
            <FlipBlock
              icon={imageFeature01}
              title="НЕ ТРЕБУЕТ МОНТАЖА"
              description="Просто наклейте I-STICKER на лобовое стекло и он готов к использованию"
            />
            <FlipBlock
              icon={imageFeature02}
              title="АВТОМАТИЧЕСКАЯ ИДЕНТИФИКАЦИЯ ВОДИТЕЛЯ"
              description="Водитель определяется автоматически без дополнительного оборудования"
            />
            <FlipBlock
              icon={imageFeature03}
              title="АНАЛИЗ СТИЛЯ ВОЖДЕНИЯ И РЕЙТИНГ"
              description="Водителям присваиваются рейтинги за поездку или период в зависимости от их стиля вождения"
            />
            <FlipBlock
              icon={imageFeature04}
              title="ФИКСАЦИЯ И РЕКОНСТРУКЦИЯ ДТП"
              description="Встроенные датчики помогают в разборе ДТП"
            />
            <FlipBlock
              icon={imageFeature05}
              title="UBI/BBI COMPLIANT"
              description="Идеально подходит для программ 'умного страхования'"
            />

            <FlipBlock
              icon={imageFeature06}
              title="ПОДДЕРЖКА ПРОГРАММ УМНОГО СТРАХОВАНИЯ"
              description="Открывает новые перспективы для страховых программ"
            />
            <FlipBlock
              icon={imageFeature07}
              title="УВЕДОМЛЕНИЕ О ВАНДАЛИЗМЕ И САБОТАЖЕ"
              description="Попытки вандализма автоматически фиксируются в отчетах"
            />
            <FlipBlock
              icon={imageFeature08}
              title="УДОБНЫЙ ВЕБ-ПОРТАЛ И МОБИЛЬНОЕ ПРИЛОЖЕНИЕ"
              description="Простые и удобные веб-портал и мобильное приложение"
            />
            <FlipBlock
              icon={imageFeature09}
              title="АВТОМАТИЧЕСКИЕ ОТЧЕТЫ И УВЕДОМЛЕНИЯ"
              description="Автоматические отчеты отправляются по email или текстовыми сообщениями"
            />
            <FlipBlock
              icon={imageFeature10}
              title="КОНТРОЛЬ РАСХОДА ТОПЛИВА И ВЫБРОСОВ СО2"
              description={
                <>
                  Снижение ваших расходов на топливо и выбросов CO2
                </>
              }
            />
          </FeaturesWrapper>
        </Container>
      </Section>

      <Section backgroundColor="#F5F5F5">
        <Container>
          <VideoBlock code="K-bji7YGL8g" />
        </Container>
      </Section>

      <Section backgroundColor="#1C2F75">
        <Container>
          <SectionTitle variant="inverted">Преимущества</SectionTitle>
          <EdgeWrapper>
            <EdgeBlock
              icon={imageEdge01}
              title="Не требует монтажа"
              description="Экономит время и нет затрат на установку"
            />
            <EdgeBlock
              icon={imageEdge02}
              title="Все, что вам нужно"
              description="Встроенные датчики предоставляют всю необходимую информацию"
            />
            <EdgeBlock
              icon={imageEdge03}
              title="Готов к эксплуатации за 1 минуту"
              description="Быстрое внедрение без хлопот"
            />
            <EdgeBlock icon={imageEdge04} title="Полностью беспроводной и автономный" description="Встроенный аккумулятор и солнечная панель освобождают от подключения к ТС" />
            <EdgeBlock
              icon={imageEdge05}
              title="Можно без сим-карты"
              description="Экономия на платежах за сотовую связь и избавляет от сложностей с сим-картами"
            />
            <EdgeBlock
              icon={imageEdge06}
              title="Защищает конфиденциальность сотрудников"
              description="Гибкость режимов передачи данных позволяет избегать образа «Большого Брата»"
            />
            <EdgeBlock
              icon={imageEdge07}
              title="Нет необходимости в демонтаже"
              description="При обновлении автопарка просто поменяйте скотч 3M® и приклейте на другой автомобиль"
            />
            <EdgeBlock
              icon={imageEdge08}
              title="Освобождает от проблем с автомобилем"
              description="Исключает возможные разряды АКБ, заводские сообщения об ошибках и другие риски по гарантии ТС"
            />
          </EdgeWrapper>
        </Container>
      </Section>

      <Section>
        <Container>
          <Card>
            <SectionTitle>Записаться на пилот</SectionTitle>
            <CardText>
              Чтобы запросить пилот, пожалуйста, заполните форму ниже и мы свяжемся с вами как можно скорее
            </CardText>
            <FormRequest />
          </Card>
        </Container>
      </Section>
    </CloudWiseIStickerStyled>
  );
}

export default CloudWiseISticker;
