import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemDescription,
  AccordionItemIcon,
  AccordionItemNameWrapper,
  AccordionItemTitle,
  AlertsSwitcherWrapper,
  Video,
  VideoWrapper,
} from './AlertsSwitcher.styled';
import signalIcon01 from '../../assets/images/mobileye8/signal-icon-01.png';
import signalIcon02 from '../../assets/images/mobileye8/signal-icon-02.png';
import signalIcon03 from '../../assets/images/mobileye8/signal-icon-03.png';
import signalIcon04 from '../../assets/images/mobileye8/signal-icon-04.png';
import signalIcon05 from '../../assets/images/mobileye8/signal-icon-05-USA.png';
import signalVideo01 from '../../assets/video/mobileye8-signal-01.mp4';
import signalVideo02 from '../../assets/video/mobileye8-signal-02.mp4';
import signalVideo03 from '../../assets/video/mobileye8-signal-03.mp4';
import signalVideo04 from '../../assets/video/mobileye8-signal-04.mp4';
import signalVideo05 from '../../assets/video/mobileye8-signal-05.mp4';
import { AlertSwitcherItemProps } from './AlertsSwitcher.types';

const alertItems = [
  {
    title: 'Предупреждение столкновения с впереди идущим ТС (включая мотоциклы) (FCW)',
    description:
      'Система отправляет (предоставляет?) звуковое и визуальное оповещение, когда она обнаруживает неизбежное столкновение с впереди идущем автомобилем или мотоциклом, как на шоссе, так и на городских дорогах. Система оповещает за 2.5 секунды до столкновения, оставляя время на реакцию.',
    icon: signalIcon01,
    video: signalVideo01,
  },
  {
    title: 'Предупреждение о возможном наезде на пешехода или велосипедиста (PCW)',
    description:
      'Система отправляет (предоставляет?) звуковое и визуальное оповещение перед неизбежным столкновением с пешеходом или велосипедистом, оставляя водителю время на реакцию. Если время до столкновения становится меньше 2х секунд, генерируется оповещение PCW.',
    icon: signalIcon02,
    video: signalVideo02,
  },
  {
    title: 'Оповещение в случае непреднамеренного съезда с полосы движения (LDW)',
    description: 'Система отправляет (предоставляет?) звуковое и визуальное оповещение, когда происходит перестроение без включения поворотников.',
    icon: signalIcon03,
    video: signalVideo03,
  },
  {
    title: 'Контроль безопасной дистанции до впереди идущего ТС (HMW)',
    description:
      'HMW отслеживает расстояние (измеряется в секундах) до впереди идущего автомобиля и, когда это время сокращается до 2.5 секунд или меньше, система Mobileye подаёт сигнал о том, что допустимая дистанция менее или равна установленному параметру.',
    icon: signalIcon04,
    video: signalVideo04,
  },
  {
    title: 'Распознавание дорожных знаков (SLI), а также информирование о превышении разрешенной скорости(TSR)',
    description:
      'Mobileye сканирует дорожные знаки скорости вдоль дороги. Когда водитель превышает допустимую скорость, визуальный индикатор предупреждает его - полезное напоминание для водителя в поддержании безопасной скорости.',
    icon: signalIcon05,
    video: signalVideo05,
  },
];

const AlertSwitcherItem = ({ title, description, icon, isActive, onClick }: AlertSwitcherItemProps): JSX.Element => (
  <AccordionItem onClick={onClick} isActive={isActive}>
    <AccordionItemNameWrapper>
      <AccordionItemTitle>{title}</AccordionItemTitle>
      <AccordionItemDescription>{description}</AccordionItemDescription>
    </AccordionItemNameWrapper>
    <AccordionItemIcon>
      <img width="100" src={icon} alt={title} />
    </AccordionItemIcon>
  </AccordionItem>
);

const AlertsSwitcher = (): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderedAlerts = alertItems.map((item, index) => (
    <AlertSwitcherItem
      title={item.title}
      description={item.description}
      icon={item.icon}
      isActive={index === activeIndex}
      onClick={() => {
        setActiveIndex(index);
      }}
      // eslint-disable-next-line react/no-array-index-key
      key={index}
    />
  ));

  return (
    <AlertsSwitcherWrapper>
      <Accordion>{renderedAlerts}</Accordion>
      <Video>
        <VideoWrapper>
          <video src={alertItems[activeIndex].video} muted autoPlay playsInline loop />
        </VideoWrapper>
      </Video>
    </AlertsSwitcherWrapper>
  );
};

export default AlertsSwitcher;
