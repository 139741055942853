import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '../../components/Modal/Modal';
import {
  ModalFeatureBlock,
  ModalFeaturesList,
  ModalLead,
  ModalList,
  ModalMainImage,
  ModalMainWrapper,
} from './MixVision.styled';
import mainImage from '../../assets/images/mix-vision/road-facing-camera.png';
import { enableBodyScroll } from '../../lib/scrollLock';
import icon01 from '../../assets/images/mix-vision/icon_1-1.png';
import icon02 from '../../assets/images/mix-vision/icon_1-2.png';
import icon03 from '../../assets/images/mix-vision/icon_1-3.png';

const MixVisionModal01 = NiceModal.create(() => {
  const modal = useModal();
  return (
    <Modal
      title="Камера, направленная на дорогу"
      variant="white"
      onClose={() => {
        enableBodyScroll();
        modal.remove();
      }}
    >
      <ModalLead>
        Камера, направленная на дорогу, распознает события на дороге, включая предупреждения о столкновении, смену полосы и соблюдение дистанции.
      </ModalLead>
      <ModalMainWrapper>
        <div>
          <ModalMainImage>
            <img src={mainImage} alt="Road-Facing Camera" />
          </ModalMainImage>
        </div>
        <div>
          <ModalList>
            <li>Крепится на лобовое стекло</li>
            <li>Распознает события на дороге</li>
            <li>HD камера определяет события ADAS</li>
            <li>Цветное изображение</li>
            <li>1080p 20 кадров в секунду</li>
            <li>Встроенный модем Cat4 LTE с поддержкой GSM/GPRS/EDG</li>
            <li>Встроенный микрофон для записи звука</li>
            <li>
              Встроенный динамик для звуковых сигналов и предупреждений.<br />
              Звуковой сигнал и предупреждения могут быть отключены и заменены на визуальные оповещения
            </li>
          </ModalList>
        </div>
      </ModalMainWrapper>

      <ModalFeaturesList>
        <ModalFeatureBlock>
          <div>
            <img src={icon01} height="50px" alt="Lane Departure" />
          </div>
          <div>
            <p>
              <strong>Смена полосы</strong>
            </p>
            <p>
              Отслеживает опасную смену полос, когда переднее колесо собирается пересечь полосу без включенного поворотника (поворотник должен быть включен как минимум за 3 секунды).
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon02} height="50px" alt="Collision Warning" />
          </div>
          <div>
            <p>
              <strong>Предупреждение о возможном столкновении</strong>
            </p>
            <p>
              Отслеживает предупреждения о столкновении с ADAS (загрузка видео запускается сразу же и доступна на MiX Fleet Manager)
            </p>
          </div>
        </ModalFeatureBlock>
        <ModalFeatureBlock>
          <div>
            <img src={icon03} height="50px" alt="Following Distance" />
          </div>
          <div>
            <p>
              <strong>Соблюдение дистанции</strong>
            </p>
            <p>
              Соблюдение дистанции оценивается в секундах и при возникновении опасности генерируется событие, оповещая водителя и владельца автопарка в реальном времени
            </p>
          </div>
        </ModalFeatureBlock>
      </ModalFeaturesList>
    </Modal>
  );
});

export default MixVisionModal01;
